import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import * as moment from 'moment';
import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { of, Observable } from 'rxjs';

export type DataToCheckPayment = {
  user: string;
  id: string;
  value: string;
  screen: string;
  date: Date;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient, @Inject('LOCALSTORAGE') private localStorage: Storage) {}

  login(username: number, senha: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/authenticateCupom`, {
        codigo: username,
        senha: senha,
      })
      .pipe(
        map((user) => {
          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          user.authdata = window.btoa(username + ':' + senha);
          user.expiration = moment().add(1, 'days').toDate();
          localStorage.setItem('currentUser', JSON.stringify(user));
          return true;
        })
      );
  }

  logout(): void {
    this.localStorage.removeItem('currentUser');
  }

  getCurrentUser(): any {
    return JSON.parse(this.localStorage.getItem('currentUser'));
  }

  passwordResetRequest(cpf: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/Pessoa/RecuperarSenha`, {
        cpf,
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }

  changePassword() {
    return of(true).delay(1000);
  }

  passwordReset(id: number, senha: string): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/pessoa/AlterarSenha`, {
        id,
        senha,
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }

  alterPerson(
    id: number,
    aniversario: string,
    telefone: string,
    celular: string,
    email: string,
    placa: string,
    cep: string,
    bairro: string
  ): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/pessoa/AlterarPessoa`, {
        id,
        aniversario,
        telefone,
        celular,
        email,
        placa,
        cep,
        bairro,
      })
      .pipe(
        map((retorno) => {
          return retorno;
        })
      );
  }

  saveTransaction(data: DataToCheckPayment): void {
    const { user, screen, ...dataToSave } = data;

    localStorage.setItem(`@digo/lancar/${screen}/transaction${user}`, btoa(JSON.stringify(dataToSave)));
  }
  
  getTransaction(user: number, screen: string): string | null {
    const transactionData = localStorage.getItem(`@digo/lancar/${screen}/transaction${user}`);
    if (transactionData) {
      return atob(transactionData);
    }
    return null;
  }
}
