import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from './notification.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private ns: NotificationService, private zone: NgZone) {
    super();
  }

  handleError(errorResponse: HttpErrorResponse | any) {
    if (errorResponse instanceof HttpErrorResponse) {
      const message = errorResponse.error.message;
      this.zone.run(() => {
        switch (errorResponse.status) {
          case 403:
            this.ns.notify(message || 'Não autorizado');
            break;
          case 404:
            this.ns.notify(message || 'Recurso não encontrado. Verifique o console para mais detalhes.');
            break;
        }
      });
    }
    super.handleError(errorResponse);
  }
}
