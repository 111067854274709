import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'vantagens',
    loadChildren: './vantagens/vantagens.module#VantagensModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'vantagem',
    loadChildren: './vantagem/vantagem.module#VantagemModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'recibo',
    loadChildren: './recibo/recibo.module#ReciboModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'cupons',
    loadChildren: './cupons/cupons.module#CuponsModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'cupom',
    loadChildren: './cupom/cupom.module#CupomModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'saude',
    loadChildren: './saude/saude.module#SaudeModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'fitness',
    loadChildren: './fitness/fitness.module#FitnessModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'resgates',
    loadChildren: './resgates/resgates.module#ResgatesModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'creditos',
    loadChildren: './creditos/creditos.module#CreditosModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'clientes',
    loadChildren: './clientes/clientes.module#ClientesModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'assinaturas',
    loadChildren: './assinaturas/assinaturas.module#AssinaturasModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: './account/account.module#AccountModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'icons',
    loadChildren: './icons/icons.module#IconsModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    loadChildren: './about/about.module#AboutModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'extrato',
    loadChildren: './extrato/extrato.module#ExtratoModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'pontuacao',
    loadChildren: './pontuacao/pontuacao.module#PontuacaoModule',
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
